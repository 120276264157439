<template>
  <div style="min-width: 1540px">
    <subMenu key="subMenu" :activeTab="activeTab" :menuList="menuList" @handItem="handItem"></subMenu>
    <div class="main-header" style="min-width: 1540px">
      <div class="nav-width">
        <div class="nav-frist">
          <div class="flex-cloumn-center" ref="targetElement">
            <h1 class="company-name" style="margin-top: 40px">公司介绍</h1>
            <div class="b-line"></div>
            <div class="flex-row-center" style="margin-bottom: 10vw">
              <div style="width: 38vw; margin-right: 2vw">
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;邦建通科技集团股份有限公司总部于广西南宁，前身为言信物联网科技有限责任公司，成立于2019年8月，注册资本：伍仟万元。专精特新科技企业，公司拥有专业的技术研发团队40多人，联合创始成员10人，拟计划全国设立六大区域三十一家全资子公司，300多个分公司，2800多个办事处，集全国各地城市合伙人以股权与分红权激励进行合作。完善的管理体系与企业文化，创新的商业理念赢得终端用户的信任支持。
                </div>
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;邦建通数字建筑平台目前已与全国各地公共资源交易中心、国家建筑市场监督、信用中国、工商照面、多个通信运营商以及启信宝等官方与商业系统API接入。全国建筑协会、金融机构、保险公司、全国建筑施工企业与大型制造业企业产生战略合作伙伴关系。
                </div>
                <div class="text">
                  &nbsp; &nbsp; &nbsp;
                  &nbsp;集团把科技创新放在发展大局的核心位置，坚持以推动建筑行业发展为己任，积极探索和打造建筑行业生态体系，构建了“移动招投标管理体系、数字化施工管理体系、供应链金融生态体系、农民工数字管理体系、建筑业智库服务体系”等建设工程领域全生命周期数字化管理服务系统，全面赋能建筑行业全过程在线化与数字化的行业领跑者。
                </div>
              </div>
              <div style="width: 22vw">
                <el-image style="width: 22vw; height: 23vw"
                  src="https://oss.yanxin7.cn/2023-08-11/dcd6fde971ec7294090f9c5ee5da7072"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-cloumn-center" ref="me" style="margin-top: -260px">
        <div class="nav-frist2" style="margin-top: 5vw">
          <div class="flex-cloumn-center">
            <h1 class="company-name">联系我们</h1>
            <div class="b-line"></div>
          </div>
        </div>
        <div class="map">
          <el-image src="https://oss.yanxin7.cn/2023-08-11/e39ec15a19472318605ba6c9858057b1"></el-image>
          <div class="adress">
            <el-image style="width: 300px"
              src="https://oss.yanxin7.cn/2023-08-11/29b92dd83698a1fec77f5f48616b7ad3"></el-image>
          </div>

          <div class="contact">
            <div class="flex-row-start bd-b">
              <el-image style="
                  width: 1.5vw;
                  margin-top: -24px;
                  height: 1vw;
                  margin-right: 5px;
                " src="https://oss.yanxin7.cn/2023-08-11/9bbee204c0b31fffe2c9818430ff6ca4"></el-image>
              <div style="margin-top: -24px" class="min-width90">
                运营地址：
              </div>
              <div>南宁市青秀区中柬路9号利海·亚洲国际5号楼5单元2101号</div>
            </div>
            <div class="flex-row-start mb-20">
              <el-image style="width: 1vw; height: 1vw; margin-right: 5px"
                src="https://oss.yanxin7.cn/2023-08-11/6561bf2a8c791e1530cd14b7a7ec2725"></el-image>
              <div class="min-width90">加盟热线：</div>
              <div class="">0771-4985999</div>
            </div>
            <div class="flex-row-start">
              <el-image style="width: 1vw; height: 1vw; margin-right: 5px"
                src="https://oss.yanxin7.cn/2023-08-11/6561bf2a8c791e1530cd14b7a7ec2725"></el-image>
              <div class="min-width90">商务热线：</div>
              <div class="">13055539999</div>
            </div>
          </div>
        </div>
        <div style="height: 100px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "../components/video/video.vue";
import newProduct from "../components/news/news.vue";
import ecosystemList from "../components/ecosystemList/ecosystemList.vue";
import partner from "../components/partner/partner.vue";
import { mapState } from "vuex";
import {
  urlCommonColumnList,
  urlSystemList,
  getCatory,
  getIndustryInformationPage,
  urlBrowseUserStatTheme1,
} from "@/api/engineering";
import LoginForm from "@/components/login-form";
export default {
  data() {
    return {
      activeName: "home",

      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home", url: "" },
        { label: "应用系统", name: "ecosystem", url: "" },
        { label: "行业资讯", name: "news", url: "" },
        { label: "荣誉证书", name: "books", url: "" },
        { label: "战略合作", name: "partner", url: "" },
        { label: "联系我们", name: "about", url: "" },
      ],
      activeTab: "about",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() { },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    LoginForm,
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "联系我们";
    // document.title = "邦建通数字建筑平台";

  },
  methods: {
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },

    // 获取注册用户数
    async getUrlBrowseRegUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseUserStatTheme1", error);
      }
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          // pageSize: 100,
          // pageNum: 1,
          current: 1,
          size: 20,
          industryInformationClassId: -1,
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/home/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/recruit/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },

    toEnterpriseService(routerPath = "/insurance/home/index") {
      const host = window.location.host;
      let targetUrlPrefix = "";
      // TODO: 测试环境条测试，正式跳正式
      if (
        host.includes("dev.yanxin7.cn") ||
        host.includes("localhost") ||
        host.includes("192.168")
      ) {
        targetUrlPrefix = "https://dev.yanxin7.cn" + "/bxbh/#";
      } else {
        targetUrlPrefix = window.location.origin + "/bxbh/#";
      }
      window.open(targetUrlPrefix + routerPath);
    },

    toPath(url) {
      console.log("url", url);

      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },

    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.companyList = data.filter((item) => item.type == "home");
        console.log("data=xx==>", data);
      } catch (error) {
        console.log("ee", error);
      }
    },

    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },

    handItem(tab) {
      console.log("tap---->", tab.name);
      // window.open(item.params);
    },
    handNavItem(item, index) {
      this.navActive = index;
    },
  },
};
</script>

<style>
/* 进入动画 */
.v-enter-active {
  animation: move 1s;
}

/* 离开动画 */
.v-leave-active {
  animation: move 1s reverse;
}

@keyframes move {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translate(0);
  }
}
</style>

<style scoped lang="less">
.flex-between {
  display: flex;
  justify-content: space-between;
}

.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}

::v-deep .nav-width #content.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  background-color: white !important;
  color: #007ef2 !important;
}

.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}

.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.search-for {
  width: 100%;
  height: 19vw !important;
  color: white;
  text-align: center;
  background-image: url("https://oss.yanxin7.cn/2023-08-15/d42ec178c8394c7041a57a65120dcbd1");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 1;
}

.el-tabs--border-card {
  background: transparent;
  box-shadow: none;
}

.nav-left {
  width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: black;
}

.nav-width {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-frist {
  width: 62vw;
}

.b-line {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #2288fc;
  margin-bottom: 5vw;
}

.company-name {
  font-weight: bold;
  font-size: 32px;
}

.flex-row-center {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-cloumn-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: start;
  margin-bottom: 10px;
  line-height: 38px;
}

.nav-top {
  display: flex;
  padding-top: 5vw;
  justify-content: space-between;
  margin-bottom: 5vw;
}

.nav-flex {
  display: flex;
  align-items: center;
}

.flex-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.c-8 {
  color: #888888;
}

.zhengshu {
  width: 100%;
  height: 103vw;
  background-color: #2c89f3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zhengshu-name {
  color: white;
  font-weight: bold;
  font-size: 32px;
}

.b-line2 {
  margin-top: 10px;
  width: 2vw;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 5vw;
}

.nav-frist2 {
  width: 62vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.originator {
  margin-top: 5vw;
  width: 100%;
  height: 21vw;
  background-image: url(https://oss.yanxin7.cn/2023-08-11/bca9bbe71fae8a86785748415028918b);
  background-repeat: no-repeat;
  position: relative;
}

.originator-store {
  width: 62vw;
  height: 35vw;
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%);
  top: -35%;

  video {
    width: 100%;
    height: 100%;
  }
}

.member {}

.map {
  position: relative;
  height: 31vw;
}

.adress {
  position: absolute;
  top: 32%;
  left: 18%;
}

.contact {
  text-align: start;
  top: 135px;
  right: 165px;
  position: absolute;
  width: 29vw;
  height: 270px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 1px rgb(223, 222, 222);
  border-radius: 16px;
  overflow: hidden;
  z-index: 5;
  padding: 60px;
  font-size: 18px;
  color: #333e4d;
}

.bd-b {
  border-bottom: 1px solid rgb(209, 207, 207);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.flex-row-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.min-width90 {
  min-width: 90px;
}

.mb-20 {
  margin-bottom: 20px;
}

.member-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
}</style>
